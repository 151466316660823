import { combineReducers } from 'redux';
import { FOLDER } from '../storeNames';
import { REDUX_AREA_SELECTION } from './areaSelection/areaSelectionActions';
import { areaSelectionReducer } from './areaSelection/areaSelectionReducer';
import { CONTACTS } from './contacts/contactsActions';
import contactsReducer from './contacts/contactsReducer';
import { FILE } from './file/fileActions';
import fileReducer from './file/fileReducer';
import folderReducer from './folder/folderReducer';
import { ITEMS } from './items/itemsActions';
import itemsReducer from './items/itemsReducer';
import { PROJECTS } from './projects/projectsActions';
import projectsReducer from './projects/projectsReducer';
import { TEAMS } from './teams/teamsActions';
import teamsReducer from './teams/teamsReducer';

import { ENTITIES } from '$redux/content/entities/entitiesActions';
import entitiesReducer from '$redux/content/entities/entitiesReducer';

export default combineReducers({
    [REDUX_AREA_SELECTION]: areaSelectionReducer,
    [ENTITIES]: entitiesReducer,
    [CONTACTS]: contactsReducer,
    [ITEMS]: itemsReducer,
    [FILE]: fileReducer,
    [FOLDER]: folderReducer,
    [PROJECTS]: projectsReducer,
    [TEAMS]: teamsReducer
});
