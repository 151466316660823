import { TeamWithMembers } from './team.types';
import { Task } from './task.types';
import { Entity } from './entity.types';
import { PROJECT_STATUS, ROLES } from '../constants/projects.constants';
import { FileStatus, projects_status } from './db/enums';
import { ActivityEventGroup } from './activity.types';
import { DefaultCastStyle } from './cast.types';
import { z } from 'zod';

export type BaseProjectType = {
    /**
     * @TJS-format uuid
     */
    uuid: string;
    name: string;
};

export type TaskCounts = {
    toDo: number;
    done: number;
    inProgress: number;
    toValidate: number;
};
export const projectSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    role: z.nativeEnum(ROLES),
    status: z.string().optional(),
    folderUuid: z.string(),
    company: z
        .object({
            uuid: z.string(),
            name: z.string(),
            color: z.string().nullable()
        })
        .nullable()
        .optional(),
    created: z.date(),
    updated: z.date().nullable(),
    endDate: z.date().nullable().optional(),
    startDate: z.date().nullable().optional(),
    isFavorite: z.boolean().optional(),
    size: z.number().optional(),
    files: z.number().optional(),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    taskCounts: z.object({
        toDo: z.number(),
        done: z.number(),
        inProgress: z.number(),
        toValidate: z.number()
    }),
    hasPlanning: z.boolean(),
    hasStats: z.boolean(),
    hasCast: z.boolean(),
    team: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    user: z.object({
        uuid: z.string(),
        email: z.string(),
        firstname: z.string().optional(),
        lastname: z.string().optional(),
        firm: z.string().optional(),
        job: z.string().optional(),
        locale: z.string()
    }),
    defaultCastStyle: z
        .object({
            textColor: z.string().nullable().optional(),
            backgroundColor: z.string().nullable().optional(),
            userFileUid: z.string().nullable().optional(),
            logoUserFileUid: z.string().nullable().optional(),
            backgroundFileUid: z.string().nullable().optional()
        })
        .nullable()
        .optional(),
    logoUrl: z.string().nullable().optional(),
    backgroundUrl: z.string().nullable().optional(),
    color: z.string().nullable(),
    hasNotificationSettings: z.boolean().optional(),
    castsDisabled: z.boolean().optional()
});
export type Project = z.infer<typeof projectSchema>;

export type ProjectsState = {
    companies: Entity[];
    currentProject:
        | (ProjectWithTasks & {
              activity?: ActivityEventGroup[];
              lastActivity?: any;
              selectedTeam?: string;
          })
        | null;
    lastProjectUuid?: string;
    planningTasks?: Task[];
};

export interface ProjectsStateCurrentProject extends Omit<Project, 'tasks'> {
    tasks?: (Task & { ordering?: number; orderingDelta?: number })[];
    selectedTeam?: string;
    activity?: any;
    lastActivity?: any;
    team_uuid: string;
    folderUuid: string;
    folderName: string;
    projectRole: string;
    client_name: string;
}
export type ProjectWithTasks = Project & {
    tasks: Task[];
};

export interface ProjectStatEntry {
    time: number;
}
export interface ProjectStatUser extends ProjectStatEntry {
    uuid: string;
    email: string;
    firstname?: string | null;
    lastname?: string | null;
    picture?: string | null;
    labels: { uid: string; name?: string | null }[];
}

interface ProjectStatLabel extends ProjectStatEntry {
    uid: string;
    name?: string | null;
    color?: string | null;
}

interface ProjectStatItem extends ProjectStatEntry {
    uuid: string;
    name: string;
    description?: string | null;
    labels: { uid: string; name?: string | null }[];
}

export type ProjectStats = {
    project: {
        uuid: string;
        name: string;
        created: Date;
        startDate?: Date | null;
        endDate?: Date | null;
        status: projects_status;
        company?: {
            name: string;
        } | null;
    };
    user: {
        uuid: string;
        email: string;
    };
    client: {
        uuid: string;
        name: string;
    };
    updated: Date;
    items: ProjectStatItem[];
    labels: ProjectStatLabel[];
    users: ProjectStatUser[];
};
export interface ProjectWithOwner {
    uuid: string;
    name: string;
    role?: string;
    status?: string;
    current_user_role?: string;
    projectRole?: string;
    isFavorite?: boolean;
    user: {
        uuid: string;
        email: string;
        firstname?: string;
        lastname?: string;
        firm?: string;
        job?: string;
    };
}
