import styles from './ScrollCalendarFilters.module.css';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import uniqBy from 'lodash/uniqBy';

import {
    calendarActiveFiltersAtom,
    calendarActiveGrouperFilters,
    calendarGrouperAtom,
    calendarItemColorTypeAtom,
    calendarItemsAtom,
    calendarProjectAtom
} from '$atoms/calendar.atoms';
import { OptionsButton } from '$components/Buttons/OptionsButton/OptionsButton';
import { FilterCheckboxes, FilterCheckboxTag } from '$components/Filters/FilterCheckboxes';
import {
    CalendarItemColorType,
    CalendarPreferences,
    ScrollCalendarGrouper
} from '$components/ScrollCalendar/ScrollCalendar.types';
import { CALENDAR_FILTERS } from '$constants/constants.sidePanels';
import { usePanel } from '$hooks/usePanel';
import { useTranslate } from '$hooks/useTranslate';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { set } from '$redux/route/routeActions';
import { updateUserPreferences } from '$redux/user/userActions';
import { preferencesSelector } from '$redux/user/userSelectors';

enum CalendarFilterEnum {
    Teams = 'Teams',
    Labels = 'Labels',
    Authors = 'Authors'
}

type Props = {
    className?: string;
    tag?: FilterCheckboxTag;
};

export function ScrollCalendarFilters({ className, tag = FilterCheckboxTag.DropDown }: Props) {
    const [activeFilters, setActiveFilters] = useAtom(calendarActiveFiltersAtom);
    const [currentGrouper, setCurrentGrouper] = useAtom(calendarGrouperAtom);
    const [itemColorType, setItemColorType] = useAtom(calendarItemColorTypeAtom);
    const items = useAtomValue(calendarItemsAtom);
    const project = useAtomValue(calendarProjectAtom);
    const setGrouperFilters = useSetAtom(calendarActiveGrouperFilters);

    const { isSmallScreen } = useSelector(isSmallScreenSelector);
    const { preferences } = useSelector(preferencesSelector);

    const { t } = useTranslate();
    const { panel, closePanel, openPanel } = usePanel();

    const dispatch = useDispatch();
    const isInProject = !!project;

    useEffect(() => {
        if (window.innerWidth >= 1380 && panel === CALENDAR_FILTERS) {
            closePanel();
        }
    }, [window.innerWidth]);

    const teams = new Set();
    const labels = new Set();
    const authors = new Set();

    items.forEach((item) => {
        if (item?.teams?.length) {
            item.teams.forEach((team) => teams.add(team));
        }
        if (item?.labels?.length) {
            item.labels.forEach((label) => labels.add(label));
        }
        if (item?.owner?.uuid) {
            authors.add(item.owner);
        }
    });

    const isMediumScreen = window.innerWidth < 1380;

    const filters = useMemo(
        () => [
            {
                type: CalendarFilterEnum.Teams,
                values: uniqBy(
                    items
                        ?.filter((item) => item?.teams?.length)
                        ?.map((item) => item.teams)
                        ?.flat(),
                    'uid'
                ).map((label) => ({
                    id: label.uuid,
                    label: label.name
                })),
                activeFilters: activeFilters[CalendarFilterEnum.Teams]
            },
            {
                type: CalendarFilterEnum.Labels,
                values: uniqBy(
                    items
                        ?.filter((item) => item?.labels?.length)
                        ?.map((item) => item.labels)
                        ?.flat(),
                    'uid'
                )?.map((label) => ({
                    id: label?.uid,
                    label: label?.name as string
                })),
                activeFilters: activeFilters[CalendarFilterEnum.Labels]
            },
            {
                type: CalendarFilterEnum.Authors,
                values: uniqBy(
                    items?.filter((item) => item?.owner?.uuid)?.flat(),
                    (item) => item.owner.uuid
                ).map((item) => ({
                    id: item.owner.uuid!,
                    label: item.owner.email
                })),
                activeFilters: activeFilters[CalendarFilterEnum.Authors]
            }
        ],
        [items, activeFilters]
    );

    const filtersWithValues = filters.filter((filter) => filter.values.length > 0);

    const handleSetFilter = (type: string, _b: any, checkedList: string[]) => {
        setActiveFilters((prevFilters) => ({
            ...prevFilters,
            [type]: checkedList
        }));
    };

    const handleOnChangeGrouper = (grouper: ScrollCalendarGrouper) => {
        setGrouperFilters([]);
        setCurrentGrouper(grouper);
        // update the query param grouper
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('grouper', grouper);
        dispatch(set(window.location.pathname + '?' + queryParams.toString(), true, true));
        if (project?.uuid) return;
        dispatch(
            updateUserPreferences({
                ...preferences,
                calendar: {
                    ...preferences.calendar,
                    globalPlanning: {
                        ...preferences?.calendar?.globalPlanning,
                        grouper,
                        grouperFilters: [] // reset filters when changing grouper
                    }
                }
            })
        );
    };

    const handleUpdateUserCalendarPreferences = <T extends keyof CalendarPreferences>(
        key: T,
        value: CalendarPreferences[T]
    ) => {
        if (project?.uuid) return;
        const updatedCalendarPreferences = {
            ...preferences,
            calendar: {
                ...preferences.calendar,
                globalPlanning: {
                    ...preferences?.calendar?.globalPlanning,
                    [key]: value
                }
            }
        };
        dispatch(updateUserPreferences(updatedCalendarPreferences));
    };

    const handleItemColorTypeChange = (type: CalendarItemColorType) => {
        setItemColorType(type);
        handleUpdateUserCalendarPreferences('itemColorType', type);
    };

    const grouperOptions: ActionType[] = [
        {
            onClick: () => handleOnChangeGrouper(ScrollCalendarGrouper.Users),
            children: t('calendar.Users')
        },
        {
            onClick: () => handleOnChangeGrouper(ScrollCalendarGrouper.Items),
            children: t('calendar.Items')
        },
        {
            onClick: () => handleOnChangeGrouper(ScrollCalendarGrouper.Labels),
            children: t('calendar.ClientEventLabels')
        }
    ];

    if (!isInProject) {
        grouperOptions.push({
            onClick: () => handleOnChangeGrouper(ScrollCalendarGrouper.Projects),
            children: t('calendar.Projects')
        });
    }

    const eventColorOptions = Object.values(CalendarItemColorType).map((color) => ({
        onClick: () => handleItemColorTypeChange(color),
        children: t(`calendar.${color}`)
    }));

    if (isSmallScreen) {
        return (
            <Button
                icon={'filters'}
                tone={ButtonTone.Hoverable}
                onClick={() =>
                    panel === CALENDAR_FILTERS ? closePanel() : openPanel(CALENDAR_FILTERS, {})
                }
            />
        );
    }

    const groupersAndFilters = (
        <div className={cn(styles.filters, className)}>
            <OptionsButton
                options={grouperOptions}
                label={t('common.Group by')}
                selectedLabel={t(
                    `calendar.${currentGrouper.charAt(0).toUpperCase() + currentGrouper.slice(1)}`
                )}
            />
            {filtersWithValues.map((filter) => (
                <FilterCheckboxes
                    formId="calendar-filters"
                    tag={tag}
                    key={filter.type}
                    className={styles.checkboxDD}
                    label={t(`calendar.${filter.type}`)}
                    filterName={filter.type}
                    filterValues={filter.values}
                    activeFilters={activeFilters}
                    onSetFilter={handleSetFilter}
                />
            ))}
            {!isInProject && (
                <OptionsButton
                    options={eventColorOptions}
                    label={t('calendar.Event Color')}
                    selectedLabel={t(`calendar.${itemColorType}`)}
                />
            )}
        </div>
    );

    if (isMediumScreen) {
        return (
            <AbsoluteContentButton
                content={groupersAndFilters}
                contentClassName={styles.filtersDropdown}
                tagName={Button}
                direction={[DirectionX.Center, DirectionY.Bottom]}
                icon={'filters'}
                triggersOnHover={false}
                triggersOnClick={true}
                closeOnClickOutside={true}
                tone={ButtonTone.Hoverable}
            />
        );
    }

    return groupersAndFilters;
}
