import { PictureAsset } from './asset.types';
import { z } from 'zod';

export const companySchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    color: z.string().nullable()
});

export type Company = {
    uuid: string;
    name: string;
    color?: string | null;
};

export type Entity = Company;
export type FullCompany = Company & {
    phone?: string | null;
    address: {
        street?: string | null;
        city?: string | null;
        country?: string | null;
        zipCode?: string | null;
    };
    pictureAsset?: PictureAsset | null;
};
export type FullEntity = FullCompany;

export type EntitiesState = {
    list: FullEntity[];
    isLoaded: boolean;
};
